










import { defineComponent } from "@vue/composition-api";

export default defineComponent({
    name: "VerifyEmail"
})
