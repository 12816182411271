import { useApi } from "@/composables/useApi";
import { AxiosResponse } from "axios";
import { ErrorClass, ProductInfo, Voucher, VoucherList } from "@/types/typeDefinitions";


export function useVoucherAPI() {
    const { api } = useApi()

    const importVoucherList = async (productID: string, variantID: string, licenseID: string, data: string): Promise<AxiosResponse | undefined> => {
        return await api.post('api/admin/voucher/import', {
            productId: productID,
            variantId: variantID,
            licenseId: licenseID,
            data: data
        })
            .catch(() => {
                return undefined
            })
    }

    const redeemVoucher = async (voucherID: string): Promise<AxiosResponse | ErrorClass> => {
        return await api.post('api/voucher/redeem', { voucher: voucherID })
            .catch(error => {
                return (error.response.data as ErrorClass)
            })
    }

    const verifyVoucherAPI = async (voucherID: string): Promise<AxiosResponse | ErrorClass> => {
        return await api.post('api/voucher/verify', { voucher: voucherID })
            .catch(error => {
                return error.response.data as ErrorClass
            })
    }

    const getProductForVoucher = async (voucherID: string): Promise<ProductInfo | ErrorClass> => {
        const response = await api.post('api/voucher/getProduct', { voucher: voucherID })
            .catch(error => {
                return error.response.data as ErrorClass
            })

        if ((response as ErrorClass).error) return response as ErrorClass

        return (response as AxiosResponse).data as ProductInfo
    }

    const getVoucherList = async (): Promise<VoucherList[]> => {
        // console.log('sent request')
        const response = await api.get('api/admin/voucher/list')
            .catch(() => {
                return undefined
            })

        if (!response) return [/*{ voucherKey: '', productId: '', licenseId: '', user: '' }*/];
        return response.data as VoucherList[]
    }

    const generateVoucherKey = async (): Promise<string> => {
        const response = await api.get('api/admin/voucher/generateVoucher')
            .catch(() => {
                return undefined
            })

        if (!response) return ''
        return response.data
    }

    const createVoucherAPI = async (voucher: Voucher): Promise<boolean> => {
        const response = await api.post('api/admin/voucher/singleVoucher', voucher)
            .catch(() => {
                return undefined
            })

        if (!response) return false
        return true
    }

    const matchVoucherError = (errorMessage: string): string => {
        switch (errorMessage) {
            // * Since User doesn't need to know why Voucher is not valid
            case 'Voucher Invalid':
            case 'Voucher is not valid':
            case 'Product is not valid':
            case 'Variant is not valid':
                return 'invalid'
            case 'Voucher already redeemed':
                return 'redeemed'
            case 'User already has that license active':
                return 'duplicate'
            default:
                return ''
        }
    }

    return {
        importVoucherList,
        redeemVoucher,
        getProductForVoucher,
        getVoucherList,
        verifyVoucherAPI,
        matchVoucherError,
        generateVoucherKey,
        createVoucherAPI
    }
}