












































import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import router from "@/router";
import { useGlobalStorage } from "@/composables/globalStorage";
import { Bundle, ErrorClass } from '@/types/typeDefinitions';
import { useUserAPI } from "@/api_connectors/user";
import { useVoucherAPI } from "@/api_connectors/voucher";
import { useToast } from "@/composables/useToast";

export default defineComponent({
    name: "RedeemVoucher",
    props: {
        voucherKey: {
            type: String,
            required: true
        }
    },
    setup(props, { emit }) {
        const { redeemVoucher, matchVoucherError } = useVoucherAPI()
        const { showToast } = useToast()
        const { globalStore } = useGlobalStorage()
        const { getUserData } = useUserAPI()

        const userEmail = ref('')

        const bundle = computed(() => {
            return globalStore.bundle
        })

        const redeemVoucherCode = async (closeModal: Function): Promise<void> => {
            const response = await redeemVoucher(props.voucherKey)
            if ((response as ErrorClass).error) {
                const errorInfo = matchVoucherError((response as ErrorClass).errorMessage)
                emit('additional-info', errorInfo)
                return showToast('failed-request')
            }
            showToast('success-request')

            if (globalStore.bundle) {
                switch (globalStore.bundle) {
                    case Bundle.themagicflute: {
                        await router.push('/themagicflute').catch(() => {
                        })
                        break;
                    }
                    case Bundle.worldofmusic: {
                        if (globalStore.bundle !== Bundle.worldofmusic) {
                            router.push('/appSteps').catch(() => {
                            })
                        } else {
                            router.push({ name: 'PickUserType' }).catch(() => {
                            })
                        }
                        break;
                    }
                }
            }

            closeModal()
        }

        onMounted(async () => {
            const response = await getUserData()
            if (!response) return
            userEmail.value = response.email
        })

        return {
            bundle,
            Bundle,
            userEmail,
            redeemVoucherCode
        }
    }
})
